import React from "react";
import "../../components/css/staticSlider.css";
import ApleIOS from "../../assets/static/appstore_en.webp";
import PlayStoreIcon from "../../assets/static/googleplay_en.webp";
import AppImage from "../../assets/static/app.png";

function Slider() {
  return (
    <div className="container-fluid py-5 navCustomCss">
      <div className="container">
        <div className="row align-items-center">
          {/* Text on the left on the slider */}
          <div className="col-md-6">
            <h1 className="headingText1">Banking</h1>
            <h1 className="headingText2">Everyday and Everywhere</h1>
            <br />
            <p className="text">Streamline your finances effortlessly</p>
            <br />
            <p className="text">Manage your money effortlessly.</p>
            <br />
            <p className="text">Discover banking made simple</p>
            <div className="mb-3">
            <a href="https://play.google.com/store/games?hl=en" target="__blank">
            <img
                src={PlayStoreIcon}
                alt="Kontriman Google App Store icon"
                className="img-fluid app-icon"
              />
            </a>

            <a href="https://www.apple.com/app-store/" target="__blank">
             <img
                src={ApleIOS}
                alt="Kontriman IOS Store icon"
                className="img-fluid app-icon"
              />
             </a>
            </div>
            <br />
            <div className="d-flex align-items-center">
              <a href="/help-center">
              <button className="btn btn-mute text-white">Discover More</button>
              </a>
            </div>
          </div>

          {/* Image on the right on the slider - Hidden on mobile */}
          <div className="col-md-6 d-none d-md-flex justify-content-end">
            <img src={AppImage} alt="Sample" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider;
