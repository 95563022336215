import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import EmptySlider from '../sliders/EmptySlider';
import FlightImage from "../../assets/static/support.png";

function BusinessOnlineBanking() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
      <EmptySlider 
        title="Business Online Banking? We’ve got you covered." 
        description="Kontriman offers various ways to support your Business Online Banking – through our help center, chat support, and email. Get the assistance you need, anytime and anywhere." 
        imageUrl={FlightImage}
    />
 
    {/* Content will be place here for how does personal account works or how to open a person
    account with Kontriman
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default BusinessOnlineBanking;
