import React from 'react'
import BlogNav from '../Nav/BlogNav';
import EmptySlider from '../sliders/EmptySlider'
import OpenNewAccount from '../home/OpenNewAccount'
import Footer from '../footer/Footer'
import emptySliderIcon from "../../assets/static/4_still_curious.webp";


export default function Blog() {
  return (
    <div>
    <BlogNav></BlogNav>
    <br></br><br></br>
    <EmptySlider 
        title="Stay Informed with Kontriman" 
        description="Kontriman provides a variety of ways to keep you informed – from the help center, chat support, and email to the latest blog posts, news updates, and other media. Stay connected and get the information you need, whenever and wherever." 
        imageUrl={emptySliderIcon} 
    />
 
    <br></br><br></br><br></br>
    <OpenNewAccount></OpenNewAccount>
    <Footer></Footer>
    </div>
  )
}
