import React from 'react';
import '../css/about.css'
import emptySliderIcon from "../../assets/static/global/personal_prpfile.png";
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../components/css/join_us.css'
import joinUsImage from "../../assets/static/business.webp";

function AboutContent() {
  return (
<di>
    <div className="container-fluid py-5 navCustom">
        <div className="container">
            <div className="row align-items-center">
            {/* Text on the left */}
            <div className="col-md-12">
                <h1 className="ourStoryHeading"><strong>Our story</strong></h1>
                <p className="cusomText">
                Kontriman is a Category 1 Microfinance Institution registered under No. 23/049/CMR/SW/53/279/CCA/036004/03600400, with regulatory approvals from COBAC: D-2024/354 | MINFI: 00000041/2024 | NIU: M042318452491L. Our Head Office is in Limbe, South West Region, Cameroon.

                Founded to bridge the financial gap ignored by traditional banks, Kontriman is committed to financial inclusion, trust, and tailored solutions. Instead of waiting for change, we created it—building a system that empowers individuals and businesses. Today, we provide innovative banking solutions that go beyond transactions, helping our clients save, grow, and thrive. At Kontriman, we don’t just offer financial services—we build financial futures.
                
                </p>
            </div>
        </div>
    </div>
</div>


<Container fluid className="custom_bg">
      <Container className="container-padding">
        <Row>
          <Col md={12} className="d-flex align-items-start">
            <div className="content">
              <h1 className='text-center ourStoryHeading'><strong>Our vision is simple</strong></h1>
              <p className='text-center'>We’re not just your financial institution; we’re a dedicated partner in your business's growth and success. By choosing Kontriman, you’re selecting a trusted ally committed to your business’s financial stability, security, and long-term prosperity.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>



<Container fluid className="bg-custom">
      <Container className="container-padding">
        <Row>
          <Col md={6} className="d-flex align-items-start">
            <div className="images">
              <img src={emptySliderIcon} alt="Sample" className="img-fluid" />
            </div>
          </Col>
          <Col md={6} className="d-flex align-items-start">
            <div className="content">
              <h2>Business Banking Experience</h2>
              <h1>Why Kontriman is the Right Choice for Your Business Banking Needs</h1>
              <p>We’re not just your financial institution; we’re a dedicated partner in your business's growth and success. By choosing Kontriman, you’re selecting a trusted ally committed to your business’s financial stability, security, and long-term prosperity.</p>
              <ul className="bullet-points">
                <li><strong>Business Accounts:</strong> Create a Business Account in secounds</li>
                <li><strong>Business Loans:</strong> Apply for Business Loans in secounds</li>
                <li><strong>Business Support:</strong> Access our range of Business Support in secounds</li>
                <li>Types of Business Loans</li>
                <li>Unsecured Loans</li>
                <li>Real Estate Loans</li>
                <li>Agricultural Loans</li>
              </ul>
              <a href="/business-banking">
              <Button className="mt-3 custom-button">Open Busines Account Now</Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>

</di>
  );
}

export default AboutContent;
