import React from "react";
import '../../components/css/contentSlider.css';

function ContentSlider() {
  return (
    
    <div className="container-fluid py-5 navCustom slider-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12">
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentSlider;
