import React from 'react';
import { Spinner } from 'react-bootstrap';

const KontrimanLoader = ({ size = "lg", message = "Loading Kontriman.com..." }) => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'transparent', // Clear, fully transparent background
        zIndex: 9999,
        pointerEvents: 'none', // Optional: allows clicks through loader if needed
      }}
    >
      <Spinner animation="border" role="status" variant="primary" size={size}>
        <span className="visually-hidden">{message}</span>
      </Spinner>
      <small className="mt-2 text-dark">{message}</small>
    </div>
  );
};

export default KontrimanLoader;
