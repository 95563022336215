import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../components/css/join_us.css'
import joinUsImage from "../../assets/static/business.webp";

const JoinUs = () => {
  return (
    <Container fluid className="bg-custom">
      <Container className="container-padding">
        <Row>
          <Col md={6} className="d-flex align-items-start">
            <div className="images">
              <img src={joinUsImage} alt="Sample" className="img-fluid" />
            </div>
          </Col>
          <Col md={6} className="d-flex align-items-start">
            <div className="content">
              <h2>Business Banking Experience</h2>
              <h1>Why Kontriman is the Right Choice for Your Business Banking Needs</h1>
              <p>We’re not just your financial institution; we’re a dedicated partner in your business's growth and success. By choosing Kontriman, you’re selecting a trusted ally committed to your business’s financial stability, security, and long-term prosperity.</p>
              <ul className="bullet-points">
                <li><strong>Business Accounts:</strong> Create a Business Account in secounds</li>
                <li><strong>Business Loans:</strong> Apply for Business Loans in secounds</li>
                <li><strong>Business Support:</strong> Access our range of Business Support in secounds</li>
                <li>Types of Business Loans</li>
                <li>Unsecured Loans</li>
                <li>Real Estate Loans</li>
                <li>Agricultural Loans</li>
              </ul>
              <a href="/business-banking">
              <Button className="mt-3 custom-button">Open Busines Account Now</Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default JoinUs;
