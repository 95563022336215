import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PageNotFound from "./components/404/PageNotFound";
import HelpCenter from "./components/help_center/HelpCenter";
import Contact from "./components/help_center/ContactUs";
import PersonalBanking from "./components/menuCategories/PersonalBanking";
import BusinessBanking from "./components/menuCategories/BusinessBanking";
import WaysToBank from "./components/home/WaysToBank";
import Blog from "./components/help_center/Blog";
import ContactUs from "./components/help_center/ContactUs";
import MoneySkills from "./components/help_center/MoneySkills";
import FrequietlyAskQuestion from "./components/help_center/FrequietlyAskQuestion";
import Career from "./components/help_center/Career";
import Press from "./components/help_center/Press";
import AboutKontriman from "./components/help_center/AboutKontriman";
import OurStory from "./components/help_center/OurStory";
import Testimonies from "./components/help_center/Testimonies";
import Legal from "./components/help_center/Legal";
import PersonalAccount from "./components/personal/PersonalAccount";
import PersonalLoans from "./components/personal/PersonalLoans";
import VirtualCard from "./components/help_center/VirtualCard";
import Transfer from "./components/help_center/Transfer";
import Deposit from "./components/help_center/Deposit";
import PayBills from "./components/help_center/PayBills";
import FlightTicket from "./components/help_center/FlightTicket";
import Purchase from "./components/help_center/Purchase";
import GroupSocieties from "./components/menuCategories/GroupSocieties";
import LoginPage from "./components/authPages/Login";
import CreateAccountPage from "./components/authPages/CreateAccountPage";
import ForgotPasswordPage from "./components/authPages/ForgotPassword";
import BankTransfer from "./components/help_center/BankTransfer";
import DepositAccount from "./components/personal/DepositAccount";
import CreditAccount from "./components/personal/CreditAccount";
import SavingsAccount from "./components/personal/SavingsAccount";
import StudentAccount from "./components/personal/StudentAccount";
import DailySavingsAccount from "./components/personal/DailySavingsAccount";
import MinorAccount from "./components/personal/MinorAccount";
import IndividualAccount from "./components/personal/IndividualAccount";
import BusinessSavingsAccount from "./components/business/BusinessSavingAccount";
import BusinessDepositAccount from "./components/business/BusinessDepositAccount";
import CorperateAccount from "./components/business/CorperativeAccount";
import GroupAccount from "./components/business/GroupAccount";
import ChurchAccount from "./components/business/ChurchAccount";
import BusinessLoans from "./components/business/BusinessLoans";
import BusinessOnlineBanking from "./components/business/BusinessOnlineBanking";
import BusinessCreditAccount from "./components/business/BusinessCreditAccount";
import BusinessSupport from "./components/business/BusinessSupport";
import Njangie from "./components/groups/Njangie";
import Meetings from "./components/groups/Meetings";
import Church from "./components/groups/Church";
import KontrimanLoader from "./components/global/KontrimanLoader"; 




const router = createBrowserRouter([
  { path: "/", element: <App />, errorElement: <PageNotFound/> },
  { path: "/personal-account", element: <PersonalAccount />, errorElement: <PageNotFound/> },
  { path: "/virtual-card", element: <VirtualCard />, errorElement: <PageNotFound/> },
  { path: "/personal-loans", element: <PersonalLoans />, errorElement: <PageNotFound/> },
  { path: "/student-Account", element: <StudentAccount />, errorElement: <PageNotFound/> },
  { path: "/transfer", element: <Transfer />, errorElement: <PageNotFound/> },
  { path: "/Deposit", element: <Deposit />, errorElement: <PageNotFound/> },
  { path: "/pay-bills", element: <PayBills />, errorElement: <PageNotFound/> },
  { path: "/flight-ticket", element: <FlightTicket />, errorElement: <PageNotFound/> },
  { path: "/purchase", element: <Purchase />, errorElement: <PageNotFound/> },
  { path: "/help-center", element: <HelpCenter />, errorElement: <PageNotFound/> },
  { path: "/contact-us", element: <Contact />, errorElement: <PageNotFound/> },
  { path: "/personal-banking", element: <PersonalBanking />, errorElement: <PageNotFound/> },
  { path: "/business-banking", element: <BusinessBanking />, errorElement: <PageNotFound/> },
  { path: "/group-societies", element: <GroupSocieties />, errorElement: <PageNotFound/> },
  { path: "/ways-to-bank", element: <WaysToBank />, errorElement: <PageNotFound/> },
  { path: "/blog", element: <Blog />, errorElement: <PageNotFound/> },
  { path: "/contact-us", element: <ContactUs />, errorElement: <PageNotFound/> },
  { path: "/money-skills", element: <MoneySkills />, errorElement: <PageNotFound/> },
  { path: "/faqs", element: <FrequietlyAskQuestion />, errorElement: <PageNotFound/> },
  { path: "/career", element: <Career />, errorElement: <PageNotFound/> },
  { path: "/press", element: <Press />, errorElement: <PageNotFound/> },
  { path: "/about_kontriman", element: <AboutKontriman />, errorElement: <PageNotFound/> },
  { path: "/our-story", element: <OurStory />, errorElement: <PageNotFound/> },
  { path: "/testimonies", element: <Testimonies />, errorElement: <PageNotFound/> },
  { path: "/legal", element: <Legal />, errorElement: <PageNotFound/> },
  { path: "/login", element: <LoginPage />, errorElement: <PageNotFound/> },
  { path: "/create-account", element: <CreateAccountPage />, errorElement: <PageNotFound/> },
  { path: "/forgot_password", element: <ForgotPasswordPage />, errorElement: <PageNotFound/> },
  { path: "/bank_transfer", element: <BankTransfer />, errorElement: <PageNotFound/> },
  { path: "/deposit_ccount", element: <DepositAccount />, errorElement: <PageNotFound/> },
  { path: "/credit_account", element: <CreditAccount />, errorElement: <PageNotFound/> },
  { path: "/savings_account", element: <SavingsAccount />, errorElement: <PageNotFound/> },
  { path: "/daily_saving_account", element: <DailySavingsAccount />, errorElement: <PageNotFound/> },
  { path: "/minor_account", element: <MinorAccount />, errorElement: <PageNotFound/> },
  { path: "/individual_account", element: <IndividualAccount />, errorElement: <PageNotFound/> },
  { path: "/business_saving_account", element: <BusinessSavingsAccount />, errorElement: <PageNotFound/> },
  { path: "/business_deposit_account", element: <BusinessDepositAccount />, errorElement: <PageNotFound/> },
  { path: "/corperate_account", element: <CorperateAccount />, errorElement: <PageNotFound/> },
  { path: "/group_account", element: <GroupAccount />, errorElement: <PageNotFound/> },
  { path: "/church_account", element: <ChurchAccount />, errorElement: <PageNotFound/> },
  { path: "/business_loans", element: <BusinessLoans />, errorElement: <PageNotFound/> },
  { path: "/business_online_banking", element: <BusinessOnlineBanking />, errorElement: <PageNotFound/> },
  { path: "/business_credit_account", element: <BusinessCreditAccount />, errorElement: <PageNotFound/> },
  { path: "/business_online_support", element: <BusinessSupport />, errorElement: <PageNotFound/> },
  { path: "/njangie", element: <Njangie />, errorElement: <PageNotFound/> },
  { path: "/meetings", element: <Meetings />, errorElement: <PageNotFound/> },
  { path: "/church", element: <Church />, errorElement: <PageNotFound/> },
  { path: "/kontrimanloader", element: <KontrimanLoader />, errorElement: <PageNotFound/> },

]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}> </RouterProvider>
  </React.StrictMode>
);



reportWebVitals();
