import React from 'react'
import EmptySlider from '../sliders/EmptySlider'
import NavMenu from '../Nav/NavMenu'
import Footer from '../footer/Footer'
import DownloadAppSection from '../global/DownloadAppSection'
import '../../components/css/categories.css';
import {FaUsers,FaWallet,FaHeadset,FaMoneyBillWave} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Container, Row, Col, } from 'react-bootstrap';
import defaultSliderGlobalImage from "../../assets/static/support.png"; 
import {FaBuilding, FaCreditCard,FaGlobe} from 'react-icons/fa';

export default function BusinessBanking() {
  return (
    <div>
    <NavMenu></NavMenu>
    <br></br><br></br>
    <EmptySlider 
        title="Business Banking? We’ve got you covered." 
        description="Kontriman Business Banking provides tailored financial solutions for businesses of all sizes. From secure transactions to flexible loan options, we ensure your business has the resources it needs to grow and thrive. Enjoy seamless banking experiences designed to meet the demands of your business." 
        imageUrl={defaultSliderGlobalImage} 
    />
 
 <Container fluid className="my-5 customCon">
      <Container>
        <Row className="mb-4">
          <Col className="text-left">
            <h1>Business Banking</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4  customHover">
          <Link to="/business_deposit_account" className="text-decoration-none text-dark d-block h-100">
          <FaMoneyBillWave size="3rem" />
          <br></br><br></br>
            <h3>Deposit Accounts</h3>
            <p>How to open an account, accepted business types, choosing a plan, account verification and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/business_saving_account" className="text-decoration-none text-dark d-block h-100">
          <FaWallet size="3rem" />
          <br></br><br></br>
            <h3>Savings Accounts</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/corperate_account" className="text-decoration-none text-dark d-block h-100">
          <FaBuilding size="3rem" />
          <br></br><br></br>
            <h3>Corperate Account</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4  customHover">
          <Link to="/group_account" className="text-decoration-none text-dark d-block h-100">
          <FaUsers size="3rem" />
          <br></br><br></br>
            <h3>Group Account</h3>
            <p>How to open an account, accepted business types, choosing a plan, account verification and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/church_account" className="text-decoration-none text-dark d-block h-100">
          <FaBuilding size="3rem" />
          <br></br><br></br>
            <h3>Church Account</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/business_loans" className="text-decoration-none text-dark d-block h-100">
          <FaCreditCard size="3rem" />
          <br></br><br></br>
            <h3>Business Loans</h3>
            <p>How to open an account, accepted business types, choosing a plan, account verification and more</p>
            </Link>
          </Col>
        </Row>

        <Row>

          <Col md={4} className="mb-4  customHover">
          <Link to="/business_online_banking" className="text-decoration-none text-dark d-block h-100">
          <FaGlobe size="3rem" />
          <br></br><br></br>
            <h3>Business Online Banking</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/business_credit_account" className="text-decoration-none text-dark d-block h-100">
          <FaCreditCard size="3rem" />
          <br></br><br></br>
            <h3>Credit Account</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/business_online_support" className="text-decoration-none text-dark d-block h-100">
          <FaHeadset size="3rem" />
          <br></br><br></br>
            <h3>Business Support</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
    <DownloadAppSection></DownloadAppSection>
    <Footer></Footer>
    </div>
  )
}
