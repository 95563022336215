import React from 'react'
import EmptySlider from '../sliders/EmptySlider'
import NavMenu from '../Nav/NavMenu'
import Footer from '../footer/Footer'
import OpenNewAccount from '../home/OpenNewAccount'
import { Container, Row, Col, } from 'react-bootstrap';
import {FaMoneyBillWave,FaWallet,FaUserGraduate,FaChild,FaUser,FaCreditCard} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../../components/css/categories.css';
import FlightImage from "../../assets/static/support.png";

export default function PersonalBanking() {
  return (
    <div>
    <NavMenu></NavMenu>
    <br></br><br></br>
    <EmptySlider 
        title="Personal Banking? We’ve got you covered." 
        description="Kontriman Personal Banking offers a range of services to help you manage your finances with ease. Whether it's saving for the future, managing your daily expenses, or accessing personal loans, we are here to support you every step of the way. Experience secure, personalized banking solutions tailored to meet your individual needs." 
        imageUrl={FlightImage}
    />
 
 <Container fluid className="my-5 customCon">
      <Container>
        <Row className="mb-4">
          <Col className="text-left">
            <h1>Personal Banking</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4  customHover">
          <Link to="/savings_account" className="text-decoration-none text-dark d-block h-100">
          <FaWallet size="3rem" />
          <br></br><br></br>
            <h3>Savings Account</h3>
            <p>How to open an account, accepted business types, choosing a plan, account verification and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/deposit_ccount" className="text-decoration-none text-dark d-block h-100">
          <FaMoneyBillWave size="3rem" />
          <br></br><br></br>
            <h3>Deposit Account</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/credit_account" className="text-decoration-none text-dark d-block h-100">
          <FaCreditCard size="3rem" />
          <br></br><br></br>
            <h3>Credit Account</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4  customHover">
          <Link to="/student-account" className="text-decoration-none text-dark d-block h-100">
          <FaUserGraduate size="3rem" />
          <br></br><br></br>
            <h3>Student Account</h3>
            <p>How to open an account, accepted business types, choosing a plan, account verification and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/daily_saving_account" className="text-decoration-none text-dark d-block h-100">
          <FaWallet size="3rem" />
          <br></br><br></br>
            <h3>Daily Savings Account</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>
   

          <Col md={4} className="mb-4  customHover">
          <Link to="/minor_account" className="text-decoration-none text-dark d-block h-100">
          <FaChild size="3rem" />
          <br></br><br></br>
            <h3>Minor Account</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4  customHover">
          <Link to="/individual_account" className="text-decoration-none text-dark d-block h-100">
          <FaUser size="3rem" />
          <br></br><br></br>
            <h3>Individual Account</h3>
            <p>How to open an account, accepted business types, choosing a plan, account verification and more</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
    <OpenNewAccount></OpenNewAccount>
    <Footer></Footer>
    </div>
  )
}
