import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import EmptySlider from '../sliders/EmptySlider'
import bankTransferImage from "../../assets/static/support.png";



function BankTransfer() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
      <EmptySlider 
        title="Bank Transfer? We’ve got you covered." 
        description="Enjoy FREE Bank Transfers between Kontriman accounts! Transfer funds with ease and no extra charges" 
        imageUrl={bankTransferImage}
    />
    {/* 
    content for student account
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default BankTransfer;
