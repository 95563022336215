import React from 'react'
import NavMenu from '../Nav/NavMenu'
import Footer from '../footer/Footer'
import EmptySlider from '../sliders/EmptySlider'
import Categories from '../home/Categories'
import WantToChat from './Want_to_Chat'
import OpenNewAccount from '../home/OpenNewAccount'
import supportImage from "../../assets/static/support.png";


export default function HelpCenter() {
  return (

    <div>
    <NavMenu></NavMenu>
    <br></br><br></br>
    <EmptySlider 
        title="Questions? Get All the Answers You Need Right Here." 
        description="At Kontriman, we provide more than just traditional banking. Explore a wide range of innovative financial services tailored to meet your unique needs. From personal banking to business solutions, group management to seamless transactions, we are here to help you manage your finances effortlessly. Discover how our services can make a difference in your financial journey." 
        imageUrl={supportImage}
    />
    <Categories></Categories>
    <WantToChat></WantToChat>
    <br></br><br></br><br></br>
    <OpenNewAccount></OpenNewAccount>
    <Footer></Footer>
    </div>
  )
}


