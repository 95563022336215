import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import PageNotFoundContent from './PageNotFoundContent';
import DownloadAppSection from '../global/DownloadAppSection';

function PageNotFound() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br><br></br><br></br><br></br>
      <PageNotFoundContent></PageNotFoundContent>
      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default PageNotFound;
