import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import '../../components/css/blogNav.css';
import Logo from "../../assets/static/dark_logo.png"; // Adjust path based on file location


function NavMenu() {
  return (
    <div className="container-fluid Navcustom-bg">
      <Navbar expand="lg" className="py-4 blogNavbar ">
        <Container>
          {/* Logo */}
          <Navbar.Brand href="/">
            <img
              src={Logo}
              alt="Kontriman Logo Logo"
              className="d-inline-block align-text-top"
              style={{ height: '50px' }}
            />
          </Navbar.Brand>

          {/* Toggler for mobile view */}
          <Navbar.Toggle aria-controls="navbarNav" className='me-2' />
          <Navbar.Collapse id="navbarNav">
            <Nav className="me-auto mb-2 mb-lg-0">
              {/* Menu Items */}
          
            </Nav>
            {/* Right-side menu items */}
            <div className="d-flex align-items-center">
              <Button variant="outline-light" href="#login" className="me-2 custom-button2" >
              <i className="fas fa-search me-2"></i> Search
              </Button>
              <Button  href="#open-account" className="d-flex align-items-center custom-button">
              <i className="fas fa-bell me-2"></i> Stay Connected
              </Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavMenu;
