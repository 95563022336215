import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa"; // Importing an example icon from react-icons
import kontrimanCreatedAccountImage from "../../assets/static/authImages/register.png";

const CreateAccountPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Account Details:", {
      firstName,
      lastName,
      email,
      phone,
      password,
    });
  };

  return (
    <Container className="vh-100 d-flex align-items-center position-relative">
      {/* Icon at the Top Left */}
      <div className="position-absolute top-0 start-0 p-3">
        <a href="/">
        <FaHome size={30} color="#004080" />
        </a>
      </div>
      
      <Row className="w-100">
        {/* Left Side - Create Account Form */}
        <Col md={6} className="d-flex flex-column justify-content-center px-5">
          <h3 className="text-center mb-4">Open a Kontriman account</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              Create Account
            </Button>
          </Form>

          {/* Links for Login */}
          <div className="text-center mt-3">
            Already have an account?{" "}
            <Link to="/login" className="text-primary text-decoration-none">
              Login here.
            </Link>
          </div>
        </Col>

        {/* Right Side - Image & Text (Visible only on desktop) */}
        <Col
          md={6}
          className="d-flex flex-column align-items-center justify-content-center text-center d-none d-md-block"
        >
          <h3 className="mb-3">
            Your finances <br />
            perfectly organised.
          </h3>
          <img
            src={kontrimanCreatedAccountImage}
            alt="Create Account"
            className="img-fluid rounded"
            style={{ maxWidth: "80%", height: "auto" }}
          />
          <p className="mt-3">Secure and reliable banking at your fingertips.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateAccountPage;
