import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import emptySliderIcon from "../../assets/static/4_still_curious.webp";
import EmptySlider from '../sliders/EmptySlider'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';

function FrequietlyAskQuestion() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
    <EmptySlider 
        title="Frequently Asked Questions (FAQs)" 
        description="Get answers to your questions quickly with Kontriman’s comprehensive FAQs. From account details to services, our help center, chat support, and email keep you informed. Stay updated anytime, anywhere." 
        imageUrl={emptySliderIcon} 
    />
    {
        <Container fluid className="my-5 customCon">
        <Container>
          <Row className="mb-4">
            <Col className="text-left">
              <h1>Categories</h1>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <h3>How to open personal account</h3>
              <p>Simple, secure, and convenient. Personal accounts designed to help you manage your money with confidence—anytime, anywhere.</p>
              <a href="/personal-banking">
              <Button variant="link" className="d-flex align-items-center">
              Explore More <FaArrowRight className="ml-2" />
              </Button>
              </a>
            </Col>
            <Col md={4} className="mb-4">
              <h3>How to open business account</h3>
              <p>Smart banking built for growth. Our business accounts offer secure, flexible solutions to help you manage finances and drive success.</p>
              <a href="/business-banking">
              <Button variant="link" className="d-flex align-items-center">
              Explore More <FaArrowRight className="ml-2" />
              </Button>
              </a>
            </Col>
            <Col md={4} className="mb-4">
              <h3>How to send money online</h3>
              <p>Send money anytime, anywhere. Fast, secure, and hassle-free transfers from your account to loved ones or businesses—right from your phone or computer.</p>
              <a href="/faqs">
              <Button variant="link" className="d-flex align-items-center">
              Explore More <FaArrowRight className="ml-2" />
              </Button>
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <h3>How to start Njagie Groups</h3>
              <p>
              Join a trusted community savings group. With Njangie House, contribute regularly, receive your share in turn, and build financial security together—simple, reliable, and empowering.
              </p>
              <a href="/faqs">
              <Button variant="link" className="d-flex align-items-center">
              Explore More <FaArrowRight className="ml-2" />
              </Button>
              </a>
            </Col>
            <Col md={4} className="mb-4">
              <h3>How to open Meeting House</h3>
              <p>Stay connected and organized. Meeting House helps groups schedule, plan, and manage meetings effortlessly—bringing your community together, one meeting at a time.</p>
              <a href="/faqs">
              <Button variant="link" className="d-flex align-items-center">
              Explore More <FaArrowRight className="ml-2" />
              </Button>
              </a>
            </Col>
            <Col md={4} className="mb-4">
              <h3>Types of Account</h3>
              <p>
              We offer Savings, Current, and Business Accounts, along with Online Banking, Bank Cards, and Money Transfer services for secure and convenient banking.
              </p>
              <a href="/faqs">
              <Button variant="link" className="d-flex align-items-center">
              Explore More <FaArrowRight className="ml-2" />
              </Button>
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <h3>How to contact us</h3>
              <p>
              Contact us via phone, email, or visit our website for quick support and assistance. We're here to help!
              </p>
              <a href="/faqs">
              <Button variant="link" className="d-flex align-items-center">
              Explore More <FaArrowRight className="ml-2" />
              </Button>
              </a>
            </Col>
            <Col md={4} className="mb-4">
              <h3>The Management</h3>
              <p>Our management team is dedicated to providing excellent service and ensuring your banking needs are met with professionalism and care.</p>
              <a href="/faqs">
              <Button variant="link" className="d-flex align-items-center">
              Explore More <FaArrowRight className="ml-2" />
              </Button>
              </a>
            </Col>
            <Col md={4} className="mb-4">
              <h3>Report Fraud</h3>
              <p>
              To report fraud, contact us immediately via phone, email, or our website. We're here to assist you in resolving the issue quickly and securely.
              </p>
              <a href="/faqs">
              <Button variant="link" className="d-flex -items-center">
              Explore More <FaArrowRight className="ml-2" />
              </Button>
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
    }

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default FrequietlyAskQuestion;
