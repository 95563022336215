import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import forgotPasswordImage from "../../assets/static/authImages/register.png";


const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Reset link sent to:", email);
  };

  return (
    <Container className="vh-100 d-flex align-items-center position-relative">
      <div className="position-absolute top-0 start-0 p-3">
        <a href="/">
          <FaHome size={30} color="#004080" />
        </a>
      </div>
      <Row className="w-100">
        {/* Left Side - Forgot Password Form */}
        <Col md={6} className="d-flex flex-column justify-content-center px-5">
          <h3 className="text-center mb-4">Forgot Password</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Send Reset Link
            </Button>
          </Form>

          {/* Back to Login */}
          <div className="text-center mt-3">
            <Link to="/login" className="text-decoration-none">
              Back to Login
            </Link>
          </div>
        </Col>

        {/* Right Side - Image & Text (Visible only on desktop) */}
        <Col
          md={6}
          className="d-flex flex-column align-items-center justify-content-center text-center d-none d-md-block"
        >
          <h3 className="mb-3">Reset Your online Banking Password easily.</h3>
          <img
            src={forgotPasswordImage}
            alt="Forgot Password"
            className="img-fluid rounded"
            style={{ maxWidth: "80%", height: "auto" }}
          />
          <p className="mt-3">We’ll send you a link to reset your password securely.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordPage;
