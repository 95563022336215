import React from "react";
import PropTypes from 'prop-types';
import '../../components/css/emptySlider.css';

function EmptySlider({ title, description, imageUrl }) {
  EmptySlider.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
  };

  return (
    <di>
    <br />
    <div className="container-fluid py-5 customEmptySlider">
      <div className="container">
        <div className="row align-items-center">
          {/* Text on the left */}
          <div className="col-md-8">
            <h1 className="headingText">{title}</h1>
            <br />
            <p className="cusomText">{description}</p>
          </div>

          {/* Image on the right */}
          <div className="col-md-4 d-flex justify-content-end">
            {imageUrl && <img src={imageUrl} alt="Sample" className="img-fluid" />}
          </div>
        </div>
      </div>
    </div>
    </di>

  );
}

export default EmptySlider;
