import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import '../../components/css/navMenu.css';
import Logo from "../../assets/static/logo.png"; 


function NavMenu() {
  return (
    <div className="container-fluid custom-bg">
      <Navbar expand="lg" className="py-4">
        <Container>
          {/* Logo */}
          <Navbar.Brand href="/">
            <img
              src={Logo}
              alt="Kontriman Logo"
              className="d-inline-block align-text-top"
              style={{ height: '50px' }}
            />
          </Navbar.Brand>

          {/* Toggler for mobile view */}
          <Navbar.Toggle aria-controls="navbarNav" className="white-toggle me-2" />
          <Navbar.Collapse id="navbarNav">
            <Nav className="me-auto mb-2 mb-lg-0">
              {/* Menu Items */}
              <Nav.Link href="/personal-banking" className="nav-link-custom">Personal Banking</Nav.Link>
              <Nav.Link href="/business-banking" className="nav-link-custom">Business Banking</Nav.Link>
              <Nav.Link href="/group-societies" className="nav-link-custom">Group Society & Churches</Nav.Link>
              <Nav.Link href="/help-center" className="nav-link-custom">Help Centre</Nav.Link>
            </Nav>
            {/* Right-side menu items */}
            <div className="d-flex align-items-center">
              <Button variant="outline-light" href="/login" className="me-2">
                <i className="fas fa-sign-in-alt me-2"></i> Login
              </Button>
              <Button  href="/create-account" className="d-flex align-items-center custom-button">
                <i className="fas fa-user-plus me-4"></i> Open An Account
              </Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavMenu;
