import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import emptySliderIcon from "../../assets/static/4_still_curious.webp";
import EmptySlider from '../sliders/EmptySlider'

function Legal() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
    <EmptySlider 
        title="Kontriman Legal" 
        description="Stay informed with Kontriman's legal policies, terms, and conditions. We provide clear and accessible information regarding our services, privacy, and user agreements. Your trust is important to us, and we ensure compliance with all necessary legal requirements." 
        imageUrl={emptySliderIcon}
    />
    {/*
    Content for for FAQs will be placed here 
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default Legal;
