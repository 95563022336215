import React from 'react'
import EmptySlider from '../sliders/EmptySlider'
import NavMenu from '../Nav/NavMenu'
import Footer from '../footer/Footer'
import OpenNewAccount from './OpenNewAccount'
import { FaCode,FaWifi,FaCreditCard  } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Container, Row, Col, } from 'react-bootstrap';
import '../../components/css/categories.css';
import emptySliderIcon from "../../assets/static/4_still_curious.webp";


export default function WaysToBank() {
  return (
    <div>
    <NavMenu></NavMenu>
    <br></br><br></br>
    <EmptySlider 
        title="Way To Bank? We’ve got you covered." 
        description="Kontriman has many ways to get you the answer you need – help centre, chat support and email. Get help whenever, wherever." 
        imageUrl={emptySliderIcon}
    />

 <Container fluid className="my-5 customCon">
      <Container>
        <Row className="mb-4">
          <Col className="text-left">
            <h1>Ways to Bank</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4  customHover">
          <Link to="/" className="text-decoration-none text-dark d-block h-100">
          <FaWifi size="3rem" />
          <br></br><br></br>
            <h3>App and Internet </h3>
            <p>How to open an account, accepted business types, choosing a plan, account verification and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/" className="text-decoration-none text-dark d-block h-100">
          <FaCode size="3rem" />
          <br></br><br></br>
            <h3>USSD</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/" className="text-decoration-none text-dark d-block h-100">
          <FaCreditCard size="3rem" />
          <br></br><br></br>
            <h3>Virtual Card</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
    <OpenNewAccount></OpenNewAccount>
    <Footer></Footer>
    </div>
  )
}
