import React from 'react'
import Footer from '../footer/Footer'
import OpenNewAccount from '../home/OpenNewAccount'
import NavMenu from '../Nav/NavMenu'
import EmptySlider from '../sliders/EmptySlider'
import emptySliderIcon from "../../assets/static/support.png";

export default function ContactUs() {
  return (
    <div>
    <NavMenu></NavMenu>
    <br></br>
    <br></br>
    <EmptySlider 
        title="Need Help? Get in Touch" 
        description="Have questions or need assistance? Reach out to Kontriman through our various channels, including our help center, chat support, and email. We’re here to provide the support you need, whenever and wherever you need it." 
        imageUrl={emptySliderIcon}
    />
 
    <br></br><br></br><br></br>
    <OpenNewAccount></OpenNewAccount>
    <Footer></Footer>
    </div>
  )
}
