import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';

function Feature() {
  return (
    <Container fluid className="my-5 customCon">
      <Container>
        <Row className="mb-4">
          <Col className="text-center">
            <h1>Kontriman Products & Services </h1>
            <p>Kontriman Cooperative Credit Union offers a range of products and Services designed to help you achieve your financial goals.</p>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4">
            <h3>Personal Banking</h3>
            <p>Secure, convenient, and tailored financial solutions to manage your money with ease. Banking made simple!</p>
            <a href="/personal-banking">
            <Button variant="link" className="d-flex align-items-center">
              Discover More <FaArrowRight className="ml-2" />
            </Button>
            </a>
          </Col>
          <Col md={4} className="mb-4">
            <h3>Business Banking</h3>
            <p>Flexible financial solutions to help your business grow and thrive. Empowering businesses with smart banking!</p>
            <a href="/personal-banking">
            <Button variant="link" className="d-flex align-items-center">
            Discover More <FaArrowRight className="ml-2" />
            </Button>
            </a>
          </Col>
          <Col md={4} className="mb-4">
            <h3>Personal Loans</h3>
            <p>Fast, flexible, and reliable financial support with competitive rates and easy repayment. Get the funds you need, hassle-free!</p>
            <a href="/">
            <Button variant="link" className="d-flex align-items-center">
            Discover More <FaArrowRight className="ml-2" />
            </Button>
            </a>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4">
            <h3>Business Loans</h3>
            <p>Fast and flexible financing to support your business growth. Get the funds you need to scale!
            </p>
            <a href="/">
            <Button variant="link" className="d-flex align-items-center">
            Discover More<FaArrowRight className="ml-2" />
            </Button>
            </a>
          </Col>
          <Col md={4} className="mb-4">
            <h3>Group & Societies</h3>
            <p>Empowering communities with tailored financial solutions for collective growth and stability.</p>
            <a href="/">
            <Button variant="link" className="d-flex align-items-center">
            Discover More <FaArrowRight className="ml-2" />
            </Button>
            </a>
          </Col>
          <Col md={4} className="mb-4">
            <h3>Other Services</h3>
            <p>Our services include Online Banking for convenient access, Bank Cards for secure transactions, and Money Transfer for fast and reliable fund transfers.</p>
            <a href="/">
            <Button variant="link" className="d-flex align-items-center">
            Discover More <FaArrowRight className="ml-2" />
            </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Feature;
