import React from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import '../../components/css/main.css';
import KontrimanPageNotFoundImage from "../../assets/static/404.png";


function PageNotFoundContent() {
  return (
    <Container fluid className="vh-90 d-flex align-items-center justify-content-center">
      {/* Inner Container */}
      <Container>
      <br></br><br></br>
        <Row className="w-100">
          {/* Right Column */}
          <Col md={4} className="d-flex justify-content-center align-items-center">
            <img
              src={KontrimanPageNotFoundImage}
              alt="404 Not Found"
              className="img-fluid"
              style={{ maxWidth: "60%", height: "auto" }}
            />
          </Col>

          {/* Left Column */}
          <Col md={8} className="d-flex flex-column justify-content-center align-items-center text-center p-4">
            <h1 className="display-4">That’s an error.</h1>
            <p className="lead">
            The requested URL was not found on this server. That’s all we know.
            </p>
            <Button href="/" className="mt-3 go-back-custom-button">
            <i className="fas fa-home me-2"></i> Go Back Home
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default PageNotFoundContent;
