import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import EmptySlider from '../sliders/EmptySlider';
import FlightImage from "../../assets/static/flight.webp";

function PersonalAccount() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
      <EmptySlider 
        title="Personal Account? We’ve got you covered." 
        description="Kontriman has many ways to get you the answer you need – help centre, chat support and email. Get help whenever, wherever." 
        imageUrl={FlightImage}
    />
 
    {/* Content will be place here for how does personal account works or how to open a person
    account with Kontriman
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default PersonalAccount;
