import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import EmptySlider from '../sliders/EmptySlider'
import emptySliderIcon from "../../assets/static/4_still_curious.webp";

function OurStory() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
    <EmptySlider 
        title="Kontriman Journey" 
        description="Kontriman is built on a foundation of trust, innovation, and commitment to financial empowerment. From our humble beginnings to becoming a trusted financial partner, our journey is driven by the goal of making banking accessible and impactful for everyone." 
        imageUrl={emptySliderIcon}
    />
    {/*
    Content for for FAQs will be placed here 
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default OurStory;
