import React, { useState, useEffect } from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Slider from './components/sliders/Slider';
import Footer from './components/footer/Footer';
import WhyBankWithUs from './components/home/why_bank_with_us';
import JoinUs from './components/home/JoinUs';
import Feature from './components/home/Feature';
import Transfer from './components/help_center/Transfer';
import OpenNewAccount from './components/home/OpenNewAccount';
import ReviewSlider from './components/sliders/ReviewSlider';
import NavMenu from './components/Nav/NavMenu';
import KontrimanLoader from './components/global/KontrimanLoader';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); 
    return () => clearTimeout(timer);
  }, []);

  if (loading) return <KontrimanLoader />;

  // Your static content
  return (
    <div className="App">
      <NavMenu></NavMenu>
      <br></br><br></br><br></br>
      <Slider></Slider>
      <br></br><br></br>
      <WhyBankWithUs></WhyBankWithUs>
      <br></br><br></br>
      <JoinUs></JoinUs>
      <Feature></Feature>
      <br></br><br></br>
      <Transfer></Transfer>
      <ReviewSlider></ReviewSlider>
      <OpenNewAccount></OpenNewAccount>
      <Footer></Footer>
    </div>
  );
};


export default App;
