import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../components/css/transfer.css';
import internationalTransfersImages from "../../assets/static/global/transfer.png";

function Transfer() {
  return (
    <div>
    <Container fluid className="my-5 bg-custom">
      <Container className='container-padding'>
        <Row>
          <Col md={6} className="d-flex align-items-center ">
            <div>
              <h1 className='custom_heading'>Seamless, No-Charge Transfers with Kontriman</h1>
              <p>Enjoy <strong>FREE Bank</strong> Transfers between Kontriman accounts! Transfer funds with ease and no extra charges. Simply log in, choose your recipient, and send money instantly. It's quick, secure, and completely free—just another way Kontriman makes banking better for you.</p>
              <a href="/bank_transfer">
              <Button className='custom-button'>Discover More on Bank Transfer</Button>
              </a>
            </div>
          </Col>
          <Col md={6}>
            <img
              src={internationalTransfersImages}
              alt="International Features"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </Container>
    </div>
  );
}

export default Transfer;
