import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import '../../components/css/categories.css';
import {FaUniversity,FaMoneyCheck,FaComments,FaBookOpen,FaNewspaper,FaMoneyCheckAlt,FaBriefcase,FaUsers,FaChartLine,FaCreditCard,FaGavel,FaGlobe,FaPhone  } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Categories() {
  return (
    <Container fluid className="my-5 customCon">
      <Container>
        <Row className="mb-4">
          <Col className="text-left">
            <h1>Categories </h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4  customHover">
          <Link to="/money-skills" className="text-decoration-none text-dark d-block h-100">
          <FaBriefcase size="3rem" />
          <br></br><br></br>
            <h3>Money Skill</h3>
            <p>How to open an account, accepted business types, choosing a plan, account verification and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/faqs" className="text-decoration-none text-dark d-block h-100">
          <FaMoneyCheckAlt size="3rem" />
          <br></br><br></br>
            <h3>Faqs</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/career" className="text-decoration-none text-dark d-block h-100">
          <FaUsers size="3rem" />
          <br></br><br></br>
            <h3>Career</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>
          
        </Row>
      
        <Row>
        <Col md={4} className="mb-4  customHover">
          <Link to="/ways-to-bank" className="text-decoration-none text-dark d-block h-100">
          <FaMoneyCheck size="3rem" />
          <br></br><br></br>
            <h3>Ways to Bank </h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

           <Col md={4} className="mb-4  customHover">
          <Link to="/press" className="text-decoration-none text-dark d-block h-100">
          <FaNewspaper size="3rem" />
          <br></br><br></br>
            <h3>Press</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/about_kontriman" className="text-decoration-none text-dark d-block h-100">
          <FaUniversity size="3rem" />
          <br></br><br></br>
            <h3>About us</h3>
            <p>About the Bank, Board of Directors, Managing Team, Success Stories, Opportunities, Branches</p>
            </Link>
          </Col>
        </Row>

        <Row>
        <Col md={4} className="mb-4  customHover">
          <Link to="/blog" className="text-decoration-none text-dark d-block h-100">
          <FaNewspaper size="3rem" />
          <br></br><br></br>
            <h3>Blogs </h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

           <Col md={4} className="mb-4  customHover">
          <Link to="/our-story" className="text-decoration-none text-dark d-block h-100">
          <FaBookOpen size="3rem" />
          <br></br><br></br>
            <h3>Our Story</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/testimonies" className="text-decoration-none text-dark d-block h-100">
          <FaComments size="3rem" />
          <br></br><br></br>
            <h3>Testimonies</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>
        </Row>
      
        <br></br>
        <Row>
          <Col md={6} className="mb-4  customHover">
          <Link to="/legal" className="text-decoration-none text-dark d-block h-100">
          <FaGavel size="3rem" />
          <br></br><br></br>
            <h3>Legal</h3>
            <p>Find all our T&Cs, fee schedules, privacy policy, acceptable use policy, imprint and other legal documents</p>
            </Link>
          </Col>
  
          <Col md={6} className="mb-4 customHover">
          <Link to="/contact-us" className="text-decoration-none text-dark d-block h-100">
          <FaPhone size="3rem" />
          <br></br><br></br>
            <h3>Contact us</h3>
            <p>Can't find the answers you need? Get in touch with our support team</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Categories;
