import React from 'react';
import { Container, Row, Col ,Button} from 'react-bootstrap';
import '../../components/css/wh_bank_with_us.css'
import whyBankWithUsImages from "../../assets/static/cards-home-en.webp";

const WhyBankWithUs = () => {
  return (
    <Container fluid className="bg-white">
      <Container>
        <Row>
          <Col md={6} className="d-flex align-items-start">
            <div className="content">
              <h2>Personal Banking Experience</h2>
              <h1>Why Choose Kontriman Cooperative Credit Union?</h1>
              <p>We’re more than just a place to bank; we’re a partner in your financial journey. By choosing us, you’re choosing a financial institution that values your success, your security, and your future.</p>
              <ul className="bullet-points">
                <li><strong>Savings Account</strong>: Create a Savings account in secounds</li>
                <li><strong>Despoit Loans</strong>: Create a Deposit account  in secounds</li>
                <li><strong>Credit Account</strong>: Create a Credit account in secounds</li>
                <li>Minor Account</li>
                <li>Online Banking</li>
                <li>Mobile Banking</li>
                <li>Person Loans</li>
              </ul>
              <a href="/personal-banking">
              <Button className="mt-3 custom-button">Open Personal Account Now</Button>
              </a>
            </div>
          </Col>
          <Col md={6} className="d-flex align-items-start">
            <div className="images">
              {/* Add your images here */}
              <img src={whyBankWithUsImages} alt="Sample" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default WhyBankWithUs;
