import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import EmptySlider from '../sliders/EmptySlider'
import emptySliderIcon from "../../assets/static/4_still_curious.webp";

function Testimonies() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
    <EmptySlider 
        title="Hear From Our Customers" 
        description="Discover how Kontriman has made a difference in the lives of our customers. Read real stories and experiences from people who trust us for their financial needs." 
        imageUrl={emptySliderIcon}
    />
    {/*
    Content for for FAQs will be placed here 
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default Testimonies;
