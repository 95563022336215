import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../components/css/want_to_chat.css'; 
import kontriman_want_to_chatImage from "../../assets/static/kontriman_want_to_chat.webp";

export default function Want_to_Chat() {
  return (
    <Container fluid style={{ padding: '60px 0' }}> {/* Increased padding */}
      <Container style={{ padding: '30px', backgroundColor:'#f7f7f7' }}> {/* Increased padding */}
        <Row>
          <Col md={7}>
            <h1 className='customHeader'>Want to talk? Chat support for Kontriman customers </h1>
            <br></br><br></br>
            <p>
            We’re available on live chat to answer your questions. To access chat, log in at Holvi.com and click the chat icon in the bottom right corner.
            </p>
            <br></br><br></br>
            <p>
            Our chat hours are 10:00 – 16:00 from Monday to Friday EET (Finnish time). You can have a real conversation with us, or submit a request here.
            </p>
            <Button variant="primary" size="md" className="mt-3 customButton" style={{ textAlign: 'left' }}> {/* Reduced button size */}
              Submit Request
            </Button>
          </Col>
          <Col md={5}>
            <img
              src={kontriman_want_to_chatImage}
              alt="Kontriman Wan to Chat Icon"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
