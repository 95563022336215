import React from 'react';
import { Container, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../components/css/reviewSlider.css'

const reviews = [
  {
    id: 1,
    review: "Excellent service! Highly recommend.",
    author: "John Doe",
    rating: "⭐⭐⭐⭐⭐"
  },
  {
    id: 2,
    review: "Very satisfied with the support.",
    author: "Jane Smith",
    rating: "⭐⭐⭐⭐"
  },
  {
    id: 3,
    review: "Great experience, will use again.",
    author: "Michael Johnson",
    rating: "⭐⭐⭐⭐⭐"
  },
  {
    id: 4,
    review: "Good quality and reliable.",
    author: "Emily Davis",
    rating: "⭐⭐⭐⭐"
  },
  {
    id: 5,
    review: "The best service I've had!",
    author: "Chris Lee",
    rating: "⭐⭐⭐⭐⭐"
  },
  {
    id: 6,
    review: "Highly recommend this company.",
    author: "Sarah Brown",
    rating: "⭐⭐⭐⭐⭐"
  },
  {
    id: 7,
    review: "Service was exceptional.",
    author: "David Wilson",
    rating: "⭐⭐⭐⭐"
  },
  {
    id: 8,
    review: "Very pleased with the results.",
    author: "Laura Moore",
    rating: "⭐⭐⭐⭐⭐"
  },
  {
    id: 9,
    review: "Professional and efficient.",
    author: "James Taylor",
    rating: "⭐⭐⭐⭐"
  },
  {
    id: 10,
    review: "Top-notch customer service!",
    author: "Linda Harris",
    rating: "⭐⭐⭐⭐⭐"
  }
];

function ReviewSlider() {
  // Divide reviews into chunks of 5 for each slide
  const chunkSize = 5;
  const chunks = [];
  for (let i = 0; i < reviews.length; i += chunkSize) {
    chunks.push(reviews.slice(i, i + chunkSize));
  }

  return (
    <Container fluid className="my-5">
      <Container>
      <h2 className="text-center mb-4">What Customers Say About Us</h2>
        <Carousel>
          {chunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex">
                {chunk.map(review => (
                  <div key={review.id} className="review-column">
                    <p className="lead">"{review.review}"</p>
                    <h5>{review.author}</h5>
                    <p>{review.rating}</p>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </Container>
  );
}

export default ReviewSlider;
