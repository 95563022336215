import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../components/css/openNewAccount.css';
import GoogleIcon from "../../assets/static/googleplay_en.webp"; 
import ApleIOS from "../../assets/static/appstore_en.webp";
import international_transfersImages from "../../assets/static/international-transfers-en.webp";

function OpenNewAccount() {
  return (
    <Container fluid style={{ backgroundColor: '#f8f9fa', padding: '40px 0' }}>
      <Container style={{ padding: '0' }}>
        <Row className="align-items-center">
          {/* Left Column: Header, Description, and Icons */}
          <Col xs={12} md={8} className="d-flex flex-column justify-content-start" style={{ padding: '0 15px' }}>
            <h1 style={{ fontSize: '2.5rem', margin: '0 0 15px' }}>Download Our App</h1>
            <p style={{ margin: '0 0 20px' }}>
             At Kontri Man, we believe that daily saving is the key to unlocking a brighter financial future. Get access to all features right from your mobile device. Available on iOS and Android.
            </p>
            <div>
              <a href="https://play.google.com/store/games?hl=en" target='__blank'>
              <img src={GoogleIcon}  alt="Kontriman Google App Store icon" className="img-fluid app-icon" />
              </a>
            <a href="https://www.apple.com/app-store/" target='__blank'>
            <img src={ApleIOS} alt="Kontriman IOS Store icon" className="img-fluid app-icon" />
            </a>
            </div>
          </Col>

          {/* Right Column: Image */}
          <Col xs={12} md={4} className="d-flex justify-content-end" style={{ padding: '0' }}>
            <img
              src={international_transfersImages}
              alt="Download App"
              className="img-fluid"
              style={{ maxWidth: '70%', height: 'auto' }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default OpenNewAccount;
