import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa"; // Importing an example icon from react-icons
import userLoginImage from "../../assets/static/authImages/user_login.png";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Username:", username, "Password:", password);
  };

  return (
    <Container className="vh-100 d-flex align-items-center position-relative">
      
      <div className="position-absolute top-0 start-0 p-3">
        <a href="/"><FaHome size={30} color="#004080" /></a>
      </div>
      
      <Row className="w-100">
        {/* Left Side - Login Form */}
        <Col md={6} className="d-flex flex-column justify-content-center px-5">
          <h3 className="text-center mb-4">Log in</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Login
            </Button>
          </Form>

          {/* Links for Registration & Forgot Password */}
          <div className="text-center mt-3">
            <Link to="/forgot_password" className="text-decoration-none">
              Forgot password?
            </Link>
          </div>
          <div className="text-center mt-2">
            Don't have an account?{" "}
            <Link to="/create-account" className="text-primary text-decoration-none">
              Open one here.
            </Link>
          </div>
        </Col>

        {/* Right Side - Image & Text (Visible only on desktop) */}
        <Col
          md={6}
          className="d-flex flex-column align-items-center justify-content-center text-center d-none d-md-block"
        >
          <h3 className="mb-3">
            Your finances <br />
            perfectly organised.
          </h3>
          <img
            src={userLoginImage}
            alt="Login"
            className="img-fluid rounded"
            style={{ maxWidth: "80%", height: "auto" }}
          />
          <p className="mt-3">Secure and reliable banking at your fingertips.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
