/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { FaInstagram, FaFacebookF, FaLinkedinIn ,FaTiktok} from 'react-icons/fa';
import '../../components/css/footer.css';


function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={2} className="mb-3 mb-md-0">
            <h6>Personal Banking</h6>
            <Nav className="flex-column text-left">
              <Nav.Link href="/personal-banking">Saving Account</Nav.Link>
              <Nav.Link href="/deposit_ccount">Deposit Account</Nav.Link>
              <Nav.Link href="/credit_account">Credit Account</Nav.Link>
              <Nav.Link href="/student-account">Student Account</Nav.Link>
              <Nav.Link href="/daily_saving_account">Daily Savings Account</Nav.Link>
              <Nav.Link href="/minor_account">Minor Account</Nav.Link>
              <Nav.Link href="/individual_account">Individual Account</Nav.Link>
            </Nav>
          </Col>
          <Col md={2} className="mb-3 mb-md-0">
            <h6>Business Banking</h6>
              <Nav className="flex-column text-left">
              <Nav.Link href="/business_deposit_account">Deposit Accounts</Nav.Link>
              <Nav.Link href="/business_saving_account">Savings Account</Nav.Link>
              <Nav.Link href="/corperate_account">Corperate Account</Nav.Link>
              <Nav.Link href="/group_account">Group Account</Nav.Link>
              <Nav.Link href="/business_loans">Business Loans</Nav.Link>
              <Nav.Link href="/business_online_banking">Online Banking</Nav.Link>
              <Nav.Link href="/business_credit_account">Credit Account</Nav.Link>
              <Nav.Link href="/business_online_support">Business Support</Nav.Link>
            </Nav>
          </Col>
          <Col md={2} className="mb-3 mb-md-0">
            <h6>Group Societies</h6>
            <Nav className="flex-column text-left">
              <Nav.Link href="/njangie">Njangie</Nav.Link>
              <Nav.Link href="/meetings">Kontri Meeting</Nav.Link>
              <Nav.Link href="/njangie">Njangie House</Nav.Link>
              <Nav.Link href="#loan_application">Loan Applications</Nav.Link>

            </Nav>
          </Col>
          <Col md={2} className="mb-3 mb-md-0">
            <h6>Help Centre</h6>
            <Nav className="flex-column text-left">
            <Nav.Link href="/about_kontriman">Our Story</Nav.Link>
            <Nav.Link href="/ways-to-bank">Ways to Bank</Nav.Link>
              <Nav.Link href="/money-skills">Money Skills</Nav.Link>
              <Nav.Link href="/testimonies">Success Stories</Nav.Link>
              <Nav.Link href="/faqs">FAQs</Nav.Link>
              <Nav.Link href="/contact-us">Contact Us</Nav.Link>
              <Nav.Link href="/blog">Blog</Nav.Link>
              <Nav.Link href="/legal">Legal</Nav.Link>
            </Nav>
          </Col>
          <Col md={2}>
            <h6>Follow Us</h6>
            <Nav className="social-icons text-left">
              <Nav.Link href="https://www.instagram.com/kontrimanofficial/" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </Nav.Link>
              <Nav.Link href="https://www.facebook.com/people/Kontriman-Cooperative-Credit-Union/61552980877698/" target="_blank" rel="noopener noreferrer">
                <FaFacebookF />
              </Nav.Link>
              <Nav.Link href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn />
              </Nav.Link>
              <Nav.Link href="https://www.tiktok.com/@kontrimanofficial" target="_blank" rel="noopener noreferrer">
                <FaTiktok />
              </Nav.Link>
            </Nav>
            <br></br>
            <p className="text-white">Stay connected with us! Follow us on social media to get the latest updates, tips, and insights.</p>
          </Col>

        </Row>
        <Row>
          <Col className="text-center mt-4 text-white">
            <p>&copy; 2020-{} Kontriman Cooperative Credit Union. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
