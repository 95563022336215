import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import EmptySlider from '../sliders/EmptySlider';
import FlightImage from "../../assets/static/support.png";

function Meetings() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
      <EmptySlider 
        title="Meeting Accounts? We’ve got you covered." 
        description="Kontriman provides several ways to assist you with your meeting account – through our help center, chat support, and email. Access the support you need, whenever and wherever you need it." 
        imageUrl={FlightImage}
    />
 
    {/* Content will be place here for how does personal account works or how to open a person
    account with Kontriman
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default Meetings;
