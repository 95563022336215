import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import EmptySlider from '../sliders/EmptySlider';
import defaultSliderGlobalImage from "../../assets/static/support.png";



function PayBills() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br> <br></br>
      <EmptySlider 
        title="Pay Bills? We’ve got you covered." 
        description="Kontriman has many ways to get you the answer you need – help centre, chat support and email. Get help whenever, wherever." 
        imageUrl={defaultSliderGlobalImage} 
    />
    {/*
    Content for for FAQs will be placed here 
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default PayBills;
