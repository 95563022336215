import React from 'react'
import EmptySlider from '../sliders/EmptySlider'
import NavMenu from '../Nav/NavMenu'
import Footer from '../footer/Footer'
import OpenNewAccount from '../home/OpenNewAccount'
import {FaUsers,FaBuilding} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Container, Row, Col, } from 'react-bootstrap';
import '../../components/css/categories.css';
import supportImage from "../../assets/static/support.png";


export default function GroupSocieties() {
  return (
    <div>
    <NavMenu></NavMenu>
    <br></br><br></br>
    <EmptySlider 
        title="Group Sociesties? We’ve got you covered." 
        description="Kontriman Group & Societies Banking provides customized financial solutions for groups, societies, and organizations. Whether you’re managing contributions, handling savings, or facilitating payments, we offer a secure and reliable platform to keep your funds organized. Empower your group with our easy-to-use banking services designed to support collective financial goals." 
        imageUrl={supportImage}
    />

 <Container fluid className="my-5 customCon">
      <Container>
        <Row className="mb-4">
          <Col className="text-left">
            <h1>Group societies & churches</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4  customHover">
          <Link to="/njangie" className="text-decoration-none text-dark d-block h-100">
          <FaUsers size="3rem" />
          <br></br><br></br>
            <h3>Njangie</h3>
            <p>How to open an account, accepted business types, choosing a plan, account verification and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/meetings" className="text-decoration-none text-dark d-block h-100">
          <FaUsers size="3rem" />
          <br></br><br></br>
            <h3>Meeting</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>

          <Col md={4} className="mb-4  customHover">
          <Link to="/church" className="text-decoration-none text-dark d-block h-100">
          <FaBuilding size="3rem" />
          <br></br><br></br>
            <h3>Churches</h3>
            <p>Managing your account and users, changing addresses, passwords, closing an account and more</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
    <OpenNewAccount></OpenNewAccount>
    <Footer></Footer>
    </div>
  )
}
