import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import emptySliderIcon from "../../assets/static/4_still_curious.webp";
import EmptySlider from '../sliders/EmptySlider'

function Press() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
    <EmptySlider 
        title="Kontriman Newsroom" 
        description="Stay updated with the latest news, press releases, and media coverage about Kontriman. For inquiries, interviews, or official statements, reach out to our press team." 
        imageUrl={emptySliderIcon}
    />
    {/*
    Content for for FAQs will be placed here 
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default Press;
