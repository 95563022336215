import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import ContentSlider from '../sliders/ContentSlider';
import AboutContent from './AboutContent';


function AboutKontriman() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br><br></br>
      <ContentSlider></ContentSlider>
      <AboutContent></AboutContent>

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default AboutKontriman;
