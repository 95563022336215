import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import emptySliderIcon from "../../assets/static/4_still_curious.webp";
import EmptySlider from '../sliders/EmptySlider'


function MoneySkills() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
      <EmptySlider 
        title="How to Master Your Money with Kontriman" 
        description="Strong money skills are key to financial independence. Kontriman helps you budget, save, invest, and understand credit—empowering you to take control of your finances for a secure future." 
        imageUrl={emptySliderIcon} 
    />
    {/*
    Content for for FAQs will be placed here 
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default MoneySkills;
