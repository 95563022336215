import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import DownloadAppSection from '../global/DownloadAppSection';
import EmptySlider from '../sliders/EmptySlider'
import defaultSliderGlobalImage from "../../assets/static/support.png"; 

function StudentAccount() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
      <EmptySlider 
        title="Student Account? We’ve got you covered." 
        description="Kontriman provides multiple ways to assist you with your student account – through our help center, chat support, and email. Get the support you need, whenever and wherever you need it." 
        imageUrl={defaultSliderGlobalImage}
    />
    {/* 
    content for student account
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default StudentAccount;
