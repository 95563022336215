import React from 'react';
import NavMenu from '../Nav/NavMenu';
import Footer from '../footer/Footer';
import EmptySlider from '../sliders/EmptySlider'
import DownloadAppSection from '../global/DownloadAppSection';
import emptySliderIcon from "../../assets/static/support.png";


function Career() {
  return (
    <div>
      <NavMenu></NavMenu>
      <br></br><br></br>
    <EmptySlider 
        title="Build Your Future with Kontriman" 
        description="Explore exciting career opportunities at Kontriman. Whether you need guidance, support, or answers, our help center, chat support, and email are here for you. Start your journey with us today!" 
        imageUrl={emptySliderIcon} 
    />
    {/* Content about kontriman Loans will be placed here
    
    */}

      <DownloadAppSection></DownloadAppSection>
      <Footer></Footer>
    </div>
  );
}

export default Career;
